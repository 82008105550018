<template>
  <div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>{{ $t('catchTimeout') }}</label>
          <div class="input-group">
            <input
              v-model="flowArea.initArea.catchTimeout"
              :class="['form-control', { 'is-invalid': this.$validator.errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.CatchTimeout') }]"
              type="number"
              step="1"
              @keyup="flowArea.initArea.catchTimeout = flowArea.initArea.catchTimeout.toFixed(3)"
            >
            <div class="input-group-append">
              <span class="input-group-text">{{ $t('seconds') }}</span>
            </div>
          </div>
          <span
            v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].CatchTimeout')"
            class="badge badge-danger"
          >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.CatchTimeout') }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t('videoDirectorConfiguration.motionDetectionActive') }}</label>
          <div class="input-group">
            <toggle-button
              v-model="flowArea.initArea.motionDetectionActive"
              :labels="{ checked: 'active', unchecked: 'inactive' }"
              :class="['m-0']"
              :width="90"
              :height="30"
              :font-size="12"
            />
          </div>
          <span
            v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].CatchTimeout')"
            class="badge badge-danger"
          >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.CatchTimeout') }}</span>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label>{{ $t('pan') }}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <button
                class="btn btn-block btn-primary"
                @click="initAreaLeft()"
              >
                <font-awesome-icon
                  icon="arrow-left"
                  style="vertical-align: middle;"
                />
              </button>
            </div>
            <input
              v-model.number="flowArea.initArea.position.pan"
              :class="['form-control', { 'is-invalid': this.$validator.errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.Position.Pan') }]"
              type="number"
              min="-170"
              max="170"
              step="1"
              @keyup="flowArea.initArea.position.pan = clamp(flowArea.initArea.position.pan.toFixed(2), -170, 170)"
            >
            <div class="input-group-append">
              <span class="input-group-text">° ({{ $t('degree') }})</span>
              <button
                class="btn btn-block btn-primary"
                @click="initAreaRight()"
              >
                <font-awesome-icon
                  icon="arrow-right"
                  style="vertical-align: middle;"
                />
              </button>
            </div>
          </div>
          <span
            v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.Position.Pan')"
            class="badge badge-danger"
          >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.Position.Pan') }}</span>
        </div>
        <div class="form-group pb-4">
          <label>{{ $t('tilt') }}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <button
                class="btn btn-block btn-primary"
                @click="initAreaUp()"
              >
                <font-awesome-icon
                  icon="arrow-up"
                  style="vertical-align: middle;"
                />
              </button>
            </div>
            <input
              v-model.number="flowArea.initArea.position.tilt"
              :class="['form-control', { 'is-invalid': this.$validator.errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.Position.Tilt') }]"
              type="number"
              min="-80"
              max="80"
              step="1"
              @keyup="flowArea.initArea.position.tilt = clamp(flowArea.initArea.position.tilt.toFixed(2), -80, 80)"
            >
            <div class="input-group-append">
              <span class="input-group-text">° ({{ $t('degree') }})</span>
              <button
                class="btn btn-block btn-primary"
                @click="initAreaDown()"
              >
                <font-awesome-icon
                  icon="arrow-down"
                  style="vertical-align: middle;"
                />
              </button>
            </div>
          </div>
          <button
            class="btn btn-sm btn-primary mt-2 float-right"
            @click="moveToPosition()"
          >
            <font-awesome-icon
              class="mr-2"
              icon="compress-arrows-alt"
            />
            <span>{{ $t('moveToPosition') }}</span>
          </button>
          <span
            v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.Position.Tilt')"
            class="badge badge-danger"
          >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.Position.Tilt') }}</span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="form-group col-6">
            <label>Pan Speed</label>
            <div class="input-group">
              <input
                v-model="flowArea.initArea.position.panDegreePerSecond"
                class="form-control"
                type="number"
              >
            </div>
            <span
              v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.Position.PanDegreePerSecond')"
              class="badge badge-danger"
            >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.Position.PanDegreePerSecond') }}</span>
          </div>
          <div class="form-group col-6">
            <label>Tilt Speed</label>
            <div class="input-group">
              <input
                v-model="flowArea.initArea.position.tiltDegreePerSecond"
                class="form-control"
                type="number"
              >
            </div>
            <span
              v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.Position.TiltDegreePerSecond')"
              class="badge badge-danger"
            >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.Position.TiltDegreePerSecond') }}</span>
          </div>
        </div>
        <div class="form-group mt-3">
          <label>Calculate Pan/Tilt speed based on previous ObjectLost</label>
          <div class="input-group">
            <toggle-button
              v-model="flowArea.initArea.checkIfPreviousHasObjectLost"
              :labels="{ checked: 'active', unchecked: 'inactive' }"
              :class="['m-0']"
              :width="90"
              :height="30"
              :font-size="12"
            />
          </div>
          <span
            v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].CheckIfPreviousHasObjectLost')"
            class="badge badge-danger"
          >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.gekko.staging.lakeside.skiline.cc') }}</span>
        </div>
      </div>
    </div>
    <!-- INNER TABULATOR NAVIGATION START -->
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          ref="motionDetectionTab"
          class="nav-link active"
          href="#motionDetectionArea"
          data-toggle="tab"
          aria-controls="motionDetectionArea"
          aria-selected="true"
        >{{ $t('motionDetectionArea') }}</a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          href="#motionDetectionConfig"
          data-toggle="tab"
          aria-controls="motionDetectionConfig"
          aria-selected="false"
        >{{ $t('motionDetectionConfig') }}</a>
      </li>
    </ul>
    <!-- INNER TABULATOR NAVIGATION END -->
    <!-- INNER TABULATOR CONTENT START -->
    <div class="tab-content">
      <div
        id="motionDetectionArea"
        class="tab-pane active"
        role="tabpanel"
        aria-labelledby="motionDetectionArea"
      >
        <div class="row">
          <div class="col-12 col-md-6">
            <VisualiserFrame
              :display-all-axes="false"
              :position-x="0"
              :show-legend="false"
              :position-y="100"
              :display="['motionDetectionArea']"
            >
              <VideoDirectorConfigMotionDetectionVisualiser
                ref="motionDetectionVisualiser"
                :flow-area="flowArea"
              />
            </VisualiserFrame>
          </div>
          <div class="col-12 col-md-6">
            <table :class="['table kendo-table table-kendo', { 'is-invalid': this.$validator.errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionArea') }]">
              <colgroup>
                <col width="35">
              </colgroup>
              <thead>
                <tr>
                  <th />
                  <th>{{ $t('x') }}</th>
                  <th>{{ $t('y') }}</th>
                  <th>{{ $t('options') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(motionDetectionArea, index) in flowArea.initArea.motionDetectionArea"
                  :key="`motionDetectionArea-tableRow-${ index }`"
                >
                  <td>{{ index }}</td>
                  <td>
                    <input
                      v-model.number="motionDetectionArea.x"
                      type="number"
                      class="form-control form-control-sm"
                      min="0"
                      max="100"
                      step="1"
                      @keyup="motionDetectionArea.x = clamp(motionDetectionArea.x.toFixed(2), 0, 100)"
                    >
                  </td>
                  <td>
                    <input
                      v-model.number="motionDetectionArea.y"
                      type="number"
                      class="form-control form-control-sm"
                      min="0"
                      max="100"
                      step="1"
                      @keyup="motionDetectionArea.y = clamp(motionDetectionArea.y.toFixed(2), 0, 100)"
                    >
                  </td>
                  <td>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="removeMotionDetectionArea(index)"
                    >
                      <font-awesome-icon
                        class="mr-2 gray"
                        icon="trash"
                      />
                      <span>{{ $t('remove') }}</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <span
              v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionArea')"
              class="badge badge-danger"
            >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionArea') }}</span>
            <div class="text-right mt-2">
              <button
                class="btn btn-sm btn-primary"
                @click="addMotionDetectionArea()"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="plus"
                />
                <span>{{ $t('add') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
                  
      <div
        id="motionDetectionConfig"
        class="tab-pane"
        role="tabpanel"
        aria-labelledby="motionDetectionConfig"
      >
        <div class="form-group">
          <label>{{ $t('minHeight') }}</label>
          <input
            v-model.number="flowArea.initArea.motionDetectionObjectConfig.minimumHeight"
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionObjectConfig.MinimumHeight') }]"
            type="number"
            min="0"
            step="1"
            @keyup="flowArea.initArea.motionDetectionObjectConfig.minimumHeight = Math.round(flowArea.initArea.motionDetectionObjectConfig.minimumHeight)"
          >
          <span
            v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionObjectConfig.MinimumHeight')"
            class="badge badge-danger"
          >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionObjectConfig.MinimumHeight') }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t('minWidth') }}</label>
          <input
            v-model.number="flowArea.initArea.motionDetectionObjectConfig.minimumWidth"
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionObjectConfig.MinimumWidth') }]"
            type="number"
            min="0"
            step="1"
            @keyup="flowArea.initArea.motionDetectionObjectConfig.minimumWidth = Math.round(flowArea.initArea.motionDetectionObjectConfig.minimumWidth)"
          >
          <span
            v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionObjectConfig.MinimumWidth')"
            class="badge badge-danger"
          >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionObjectConfig.MinimumWidth') }}</span>
        </div>
        <div class="form-group pb-2">
          <label class="mr-2">{{ $t('minLifetime') }}</label>
          <span class="gray"><i>{{ $t('videoDirectorConfigRecorderFlowAreaFormComponent.minAmountOfFramesToDetectTarget') }}</i></span>
          <input
            v-model.number="flowArea.initArea.motionDetectionObjectConfig.minimumLifetime"
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionObjectConfig.MinLifetime') }]"
            type="number"
            min="0"
            step="1"
            @keyup="flowArea.initArea.motionDetectionObjectConfig.minimumLifetime = Math.round(flowArea.initArea.motionDetectionObjectConfig.minimumLifetime)"
          >
          <span
            v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionObjectConfig.MinLifetime')"
            class="badge badge-danger"
          >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionObjectConfig.MinLifetime') }}</span>
        </div>
        <div class="form-group">
          <label>{{ $t('detectionDelay') }} (seconds)</label>
          <input
            v-model.number="flowArea.initArea.motionDetectionObjectConfig.detectionDelay"
            :class="['form-control', { 'is-invalid': this.$validator.errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionObjectConfig.DetectionDelay') }]"
            type="number"
            min="0"
            step="0.5"
          >
          <span
            v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionObjectConfig.DetectionDelay')"
            class="badge badge-danger"
          >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].InitArea.MotionDetectionObjectConfig.DetectionDelay') }}</span>
        </div>
        <div class="form-group">
          <label>Snow Suppresion</label>
          <input
            v-model="flowArea.initArea.motionDetectionObjectConfig.snowSuppresion"
            type="checkbox"
            class="ml-3"
          >
        </div>
        <div class="form-group">
          <label>Shadow Suppresion</label>
          <input
            v-model="flowArea.initArea.motionDetectionObjectConfig.shadowSuppresion"
            type="checkbox"
            class="ml-3"
          >
        </div>
        <div class="form-group">
          <label>Require Upright Candidates</label>
          <input
            v-model="flowArea.initArea.motionDetectionObjectConfig.requireUprightCandidates"
            type="checkbox"
            class="ml-3"
          >
        </div>
        <div class="form-group">
          <label>Low Confidence Threshold</label>
          <input
            v-model.number="flowArea.initArea.motionDetectionObjectConfig.lowConfidenceThreshold"
            class="form-control"
            type="number"
            min="0"
            step="0.1"
          >
        </div>
      </div>
    </div>
  <!-- INNER TABULATOR CONTENT END -->
  </div>
</template>

<script>
export default {
  name: 'FlowEditorInitArea',
  components: {
    VideoDirectorConfigMotionDetectionVisualiser: () => import('@/components/Config/VideoDirector/Visualiser/VideoDirectorConfigMotionDetectionVisualiser.vue'),
    VisualiserFrame: () => import('@/components/Config/VideoDirector/Visualiser/VisualiserFrame.vue'),
  },
  props: {
    flowArea: {
      type: Object,
      required: true
    },
  },
  methods: {
    clamp (num, min, max) {
      return num <= min ? min : num >= max ? max : num;
    },
    round (number) {
      //Round values to 2 decimal digits
      return Math.round(number * 1e2) / 1e2;
    },
    initAreaUp () {
      this.flowArea.initArea.position.tilt += .25;
      this.changePtz({ pan: this.flowArea.initArea.position.pan, tilt: this.flowArea.initArea.position.tilt });
    },
    initAreaRight () {
      this.flowArea.initArea.position.pan += .25;
      this.changePtz({ pan: this.flowArea.initArea.position.pan, tilt: this.flowArea.initArea.position.tilt });
    },
    initAreaDown () {
      this.flowArea.initArea.position.tilt -= .25;
      this.changePtz({ pan: this.flowArea.initArea.position.pan, tilt: this.flowArea.initArea.position.tilt });
    },
    initAreaLeft () {
      this.flowArea.initArea.position.pan -= .25;
      this.changePtz({ pan: this.flowArea.initArea.position.pan, tilt: this.flowArea.initArea.position.tilt });
    },
    removeMotionDetectionArea (index) {
      this.flowArea.initArea.motionDetectionArea.splice(index, 1);
      this.update();
    },
    addMotionDetectionArea () {
      let x = 0;
      let y = 0;

      if (this.flowArea.initArea.motionDetectionArea.length >= 2) {
        let firstPosition = this.flowArea.initArea.motionDetectionArea[0];
        let lastPosition = this.flowArea.initArea.motionDetectionArea[this.flowArea.initArea.motionDetectionArea.length - 1];
        x = this.round((firstPosition.x + lastPosition.x) / 2);
        y = this.round((firstPosition.y + lastPosition.y) / 2);
      }

      this.flowArea.initArea.motionDetectionArea.push({ x: x, y: y });
      this.update();
    },
    changePtz (position) {
       this.$emit('changePtz', position);
    },
    moveToPosition () {
      this.changePtz({ pan: this.flowArea.initArea.position.pan, tilt: this.flowArea.initArea.position.tilt });
    },
    update () {
      this.$refs.motionDetectionVisualiser.update();
      this.$emit('update');
    },
  }
}
</script>